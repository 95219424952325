<style>
.border-0 {
  border-radius: 0px !important;
}
</style>
<template>
  <div>
    <v-data-table :disable-sort="true" :headers="headers" :items="installment_list" :items-per-page="$keys.PAGE_LENGTH"
      :loading="table_loading" class="border-a border-0 custome-header-bg" hide-default-footer>
      <template v-slot:body="{ items }">
        <tbody v-if="items.length == 0" class="text-center">
          <tr>
            <td :colspan="headers.length">
              <p class="text-center py-4 w-100"> No data found </p>
            </td>
          </tr>
        </tbody>
        <tbody v-for="item in items" :key="item.loan_id">
          <tr>
            <td>{{ item.sno }}</td>
            <td>{{ item.loan_id }}</td>
            <td>
              <p class="pt-2">{{ item.name }}</p>
              <p class="pt-1">{{ item.mobile }}</p>
              <p class="pb-2">{{ item.email }}</p>
            </td>
            <td>{{ item.loan_amount }}</td>
            <td>{{ item.agent_assigned }}</td>
            <td>
              <p style="font-size: larger;font-weight: bold;">{{ item.number_of_emi }}</p>
              <p>{{ item.due_emi }}</p>
            </td>
            <td>{{ item.payment_due_date }}</td>
            <td></td>
            <td>{{ item.monthly_emi }}</td>
            <td>{{ item.bounce_charge }}</td>
            <td>{{ item.late_fee }}</td>
            <td>{{ item.over_due_charge }}</td>
            <td>{{ item.total_payable_amount }}</td>

          </tr>
          <tr v-for="i in item.installment_list" :key="i.id" class="border-a">
            <td colspan=5></td>
            <td>{{ i.installment_number }}</td>
            <td>{{ i.payment_due_date }}</td>
            <td>{{ i.payment_date }}</td>
            <td>{{ i.installment_amount }}</td>
            <td>{{ i.bounce_charge }}</td>
            <td>{{ i.late_fee }}</td>
            <td>{{ i.over_due_charge }}</td>
            <td>{{ i.total_payable_amount }}</td>
          </tr>
          <!--          for border bottom    -->
          <tr class="border-a">
            <td :colspan="headers.length" style="height: 0px">
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
<script>

export default {
  props: ['installment_list', 'table_loading'],
  components: {},
  data() {
    return {
      headers: [
        { text: 'SN' },
        { text: 'Loan ID' },
        { text: 'Customer Details' },
        { text: 'Loan Amt' },
        { text: 'Agent' },
        {
          text: 'EMI',
        },
        { text: 'EMI Date' },
        { text: 'Payment Date' },
        { text: 'EMI Amt' },
        { text: 'Bounce Charge' },
        { text: 'Late Fee' },
        { text: 'Over due Charge' },
        { text: 'Total Paid' },
      ]
    }
  },
  methods: {}
}

</script>